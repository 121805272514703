<template>
  <div class="lender-wrapper">
    <!-- <div class="lender-banner-wrap">
      <div class="lender-banner-content">
        <div class="lender-tip wow animate__fadeInLeft">Dengan menjadi</div>
        <div class="lender-btn wow animate__fadeInLeft">Lender di BantuSaku</div>
        <div class="lender-text wow animate__fadeInLeft">Anda mendapatkan tawaran yang menarik dengan resiko yang terjaga.</div>
      </div>
    </div> -->
    <div class="lender-content-wrap hidden-xs-only">
      <div class="wow animate__bounceInRight lender-content">
        <div class="wow animate__bounceInUp lender-content-title">Dengan menjadi</div>
        <div class="wow animate__bounceInUp lender-content-lender">Lender di BantuSaku</div>
        <div class="wow animate__bounceInUp lender-content-anda">Anda akan mendapatkan tawaran yang menarik dengan risiko yang terjaga.</div>
        <img class="wow animate__bounceInUp" src="../assets/v3/home/new-afpi.png">
      </div>
    </div>
    <div class="m-lender-content-wrap hidden-sm-and-up">
      <img src="../assets/v3/lender/m-banner.png" alt="banner" class="wow animate__fadeInRight m-banner">
      <div class="m-lender-content">
        <div class="wow animate__fadeInLeft content-title">Dengan menjadi</div>
        <div class="wow animate__fadeInLeft content-lender">Lender di BantuSaku</div>
        <div class="wow animate__fadeInLeft content-anda">Anda akan mendapatkan tawaran yang menarik dengan risiko yang terjaga.</div>
        <img class="wow animate__fadeInUp" src="../assets/v3/home/new-m-afpi.png">
      </div>
    </div>
    <div class="world-wrap">
      <div class="world-content">
        <div class="world-item-box">
          <el-row :gutter="0">
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="world-card-item wow animate__fadeInUp">
                <img class="world-img" src="../assets/v3/lender/world1.png" alt="world1">
                <div>Lender</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="world-card-item wow animate__fadeInUp">
                <img class="world-img" src="../assets/v3/lender/world2.png" alt="world2">
                <div>Trusted Platform</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
              <div class="world-card-item wow animate__fadeInUp">
                <img class="world-img" src="../assets/v3/lender/world3.png" alt="world3">
                <div>Borrower</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="advantage-content">
          <div class="advantage-item-box">
            <el-row :gutter="70">
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInLeft">
                  <img class="advantage1-title" src="../assets/v3/lender/advantage1.png" alt="advantage1">
                  <div class="advantage1-text">Teknologi sistem yang paling baru dan menggunakan sistem manajemen resiko yang canggih</div>
                  <img class="advantage1-img" src="../assets/v3/lender/advantage5.png" alt="advantage5">
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInLeft">
                  <img class="advantage1-title" src="../assets/v3/lender/advantage2.png" alt="advantage1">
                  <div class="advantage1-text">Terlindungi oleh Hukum karena BantuSaku telah berizin dan diawasi oleh Otoritas Jasa Keuangan (OJK)</div>
                  <img class="advantage1-img" src="../assets/v3/lender/advantage6.png" alt="advantage6">
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInRight">
                  <img class="advantage1-title" src="../assets/v3/lender/advantage3.png" alt="advantage1">
                  <div class="advantage1-text">Memberikan suku bunga tahunan yang menarik dengan risiko rendah</div>
                  <img class="advantage1-img" src="../assets/v3/lender/advantage7.png" alt="advantage7">
                </div>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                <div class="advantage-card-item wow animate__fadeInRight">
                  <img class="advantage1-title" src="../assets/v3/lender/advantage4.png" alt="advantage1">
                  <div class="advantage1-text">Turut serta dalam membantu perekonomian masyarakat Indonesia</div>
                  <img class="advantage1-img" src="../assets/v3/lender/advantage8.png" alt="advantage8">
                </div>
              </el-col>
          </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
export default {
  data() {
    return {
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '2')
  },
}
</script>

<style lang="scss" scoped>
.lender-wrapper{
  width: 100%;
  min-height: 100vh;
  .lender-banner-wrap{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(92deg, #28543F 0%, #28543F 16%, rgba(40,84,72,0.35) 100%, rgba(40,84,72,0.35) 100%), url("../assets/v3/home/3.jpg");
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 172px;
    box-sizing: border-box;
    .lender-banner-content{
      width: 600px;
      color: #fff;
      font-weight: 700;
      .lender-tip{
        font-size: 50px;
        margin-left: 14px;
      }
      .lender-btn{
        width: 500px;
        height: 120px;
        background: #B3E872;
        border-radius: 75px;
        line-height: 120px;
        text-align: center;
        font-size: 40px;
        color: #28543F;
        margin: 14px 0 20px 0;
      }
      .lender-text{
        font-size: 30px;
        margin-left: 14px;
      }
    }
  }
  
  .m-lender-content-wrap{
    width: 100%;
    min-height: 418px;
    background: #C6E8ED;
    font-size: 20px;
    color: #1D1D1D;
    .m-banner{
      width: 100%;
      height: 230px;
    }
    .m-lender-content{
      padding: 0 50px 0 40px;
      box-sizing: border-box;
      .content-title{
        margin: 10px 0 8px 0;
      }
      .content-lender{
        color: #145246;
        margin-bottom: 16px;
      }
      .content-anda{
        font-size: 16px;
        margin-bottom: 9px;
      }
      img{
        width: 124px;
        height: 35px;
        margin-bottom: 16px;
      }
    }
  }

  .lender-content-wrap{
    width: 100%;
    height: 470px;
    background: #C6E8ED;
    .lender-content{
      max-width: 1400px;
      height: 470px;
      background: url("../assets/v3/lender/content-bg.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      padding: 50px 0 0 0;
      .lender-content-title{
        font-weight: 600;
        color: #1D1D1D;
        font-size: 44px;
      }
      .lender-content-lender{
        font-weight: 600;
        color: #145246;
        font-size: 36px;
        margin-top: 10px;
      }
      .lender-content-anda{
        width: 510px;
        font-size: 28px;
        color: #1D1D1D;
        margin: 35px 0 33px 0;
      }
      img{
        width: 189px;
      }
    }
  }
  .world-wrap{
    width: 100%;
    position: relative;
    background: #FBFFFF;
   .world-content{
      max-width: 1400px;
      margin: 0 auto;
      background: url("../assets/v3/lender/world.png") no-repeat;
      background-position: top center;
      background-size: 80%;
      overflow: hidden;
      padding: 98px 0 0 0;
      box-sizing: border-box;
      .world-item-box{
        margin-bottom: 180px;
        .world-card-item{
          text-align: center;
          font-size: 34px;
          font-weight: bold;
          .world-img{
            width: 182px;
            height: 182px;
            margin: 20px 0 40px 0;
          }
          div{
            font-size: 32px;
            color: #1D1D1D;
          }
        }
      }
      .advantage-content{
        .advantage-item-box{
          .advantage-card-item{
            margin-bottom: 80px;
          }
          .advantage1-title{
            width: 40px;
            height: 40px;
          }
          .advantage1-text{
            width: 306px;
            min-height: 156px;
            font-size: 18px;
            line-height: 28px;
            margin-top: 20px;
          }
          .advantage1-img{
            width: 326px;
            height: 304px;
          }
        }
      }
    }
  }
}
</style>